// Function to check if a string contains Chinese characters
export const containsChinese = (text) =>  /[\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\u3000-\u303F\uFF00-\uFFEF]/.test(text);
// const containsChicnese = (text) => false;

// Function to split text into parts with separate styles for Chinese and non-Chinese characters
export const splitText = (text) => {
    const parts = [];
    let currentPart = '';
    let isCurrentPartChinese = containsChinese(text[0]);

    const chinesePunctuation = /[\u3000-\u303F\uFF00-\uFFEF]/;

    Array.from(text).forEach((char) => {
        const isChinese = containsChinese(char);
        if (isChinese !== isCurrentPartChinese) {
            parts.push({ text: currentPart, isChinese: isCurrentPartChinese });
            currentPart = '';
            isCurrentPartChinese = isChinese;
        }
        currentPart += char;

        // Add a space after Chinese punctuation marks
        if (chinesePunctuation.test(char)) {
            currentPart += ' ';
        }
    });

    if (currentPart) {
        parts.push({ text: currentPart, isChinese: isCurrentPartChinese });
    }

    return parts;
};