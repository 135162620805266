import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { logEvent } from "firebase/analytics";
import { useFunctions, useUser, useAnalytics } from 'reactfire';

import "./CreateWorksheet.css";
import Navbar from "../../components/NavBar/NavBar";
import PreviewEditWorksheet from "./components/PreviewEditWorksheet";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import ConfigureWorksheet from "./components/ConfigureWorksheet";
import FinishWorksheet from "./components/FinishWorksheet";

import { gradeLevels } from "./CreateWorksheetConstants";
import { emptyDifferentiaionGroups, emptyGeneralInfo } from './CreateWorksheetObjects';
// import { sampleWorksheetMultipleChoice as sampleWorksheetResultData } from "./CreateWorksheetSampleData";


function CreateWorksheet() {
    const location = useLocation();
    const navigate = useNavigate();
    const { inputType, worksheetTemplate } = location.state || {};

    const analytics = useAnalytics();

    const [file, setFile] = useState(null);

    const [imageEncodings, setImageEncodings] = useState([]);

    // STATUSES: initial, loading, returned, finish, error
    const [status, setStatus] = useState("initial");

    const [apiMessage, setAPIMessage] = useState([]);

    const [diffGroups, setDiffGroups] = useState(structuredClone(emptyDifferentiaionGroups));

    const [generalInfo, setGeneralInfo] = useState(emptyGeneralInfo);

    const [worksheetData, setWorksheetData] = useState([]);

    // Cloud Functions
    const functions = useFunctions();
    const generateWorksheetCloudFunction = httpsCallable(functions, 'generateWorksheetWithImageInput');

    // Auth
    const { data: user } = useUser();

    // Redirect if parameters not defined
    if (inputType === undefined || worksheetTemplate === undefined) {
        navigate('/home');
    }

    /* eslint-disable no-unused-vars */
    const uploadToFirebaseTest = async (groupIndex, fileEncodings) => {
        try {
            setStatus('loading');
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);
            // console.log(token);

            console.log({
                inputType,
                originalGradeLevel: generalInfo.grade_level,
                gradeLevel: diffGroups[groupIndex].grade_level,
                numQuestions: diffGroups[groupIndex].num_questions,
                worksheetType: worksheetTemplate,
                alignToStandards: generalInfo.standards,
                accommodations: diffGroups[groupIndex].accommodations,
                language: diffGroups[groupIndex].language,
                fileName: file.name, 
                fileEncodings,
            });

            const response = await fetch('http://127.0.0.1:5001/wizlab-8c906/us-central1/generateWorksheetWithImageInput', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',  
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Headers': 'Content-Type', 
                    'Access-Control-Max-Age': '3600',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: {
                    inputType,
                    originalGradeLevel: generalInfo.grade_level,
                    gradeLevel: diffGroups[groupIndex].grade_level,
                    numQuestions: diffGroups[groupIndex].num_questions,
                    worksheetType: worksheetTemplate,
                    alignToStandards: generalInfo.standards,
                    accommodations: diffGroups[groupIndex].accommodations,
                    fileName: file.name, 
                    fileEncodings,
                    }
                }),
            });
        
            if (!response.ok) {
                setStatus("error");
                throw new Error('Network response was not ok');
            }
        
            const responseData = await response.json();
            console.log(responseData.result);
            return responseData.result;
        } catch (error) {
            setStatus("error");
            console.error('Error uploading to Firebase:', error);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    };

    /* eslint-disable no-unused-vars */
    const uploadToFirebaseCallVersion = async (groupIndex, fileEncodings) => {
        try {
            setStatus('loading');

            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken();
            // console.log("Token fetched: ", token);
            console.log({
                inputType,
                originalGradeLevel: generalInfo.grade_level,
                gradeLevel: diffGroups[groupIndex].grade_level,
                numQuestions: diffGroups[groupIndex].num_questions,
                worksheetType: worksheetTemplate,
                alignToStandards: generalInfo.standards,
                accommodations: diffGroups[groupIndex].accommodations,
                fileName: file.name, 
                fileEncodings,
            });

            const result = await generateWorksheetCloudFunction({
                inputType,
                originalGradeLevel: generalInfo.grade_level,
                gradeLevel: diffGroups[groupIndex].grade_level,
                numQuestions: diffGroups[groupIndex].num_questions,
                worksheetType: worksheetTemplate,
                alignToStandards: generalInfo.standards,
                accommodations: diffGroups[groupIndex].accommodations,
                fileName: file.name, 
                fileEncodings,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            console.log(result);

            logEvent(analytics, 'worksheet_generated_successfuly', { user: user.displayName });
        
            // const responseData = await result.json();
            const responseData = result.data;
            console.log(responseData);
            return responseData;
        } catch (error) {
            setStatus("error");
            console.error('Error uploading to Firebase:', error);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    };  

    const handleUpload = async () => {
        try {
            if (file) {
                setAPIMessage([]);
                console.log(imageEncodings);

                const promises = diffGroups.map((group, index) => 
                    uploadToFirebaseCallVersion(index, imageEncodings)
                );
                // console.log(promises);
                const results = await Promise.all(promises);
                
                const allWorksheetData = results.map((result, index) => {
                    const questionsArray = Array.isArray(result) ? result : result.questions;
                    const worksheetDataOutput = {
                        worksheet_title: generalInfo.worksheet_name,
                        worksheet_type: worksheetTemplate,
                        grade_level: diffGroups[index].grade_level,
                        accommodations:  diffGroups[index].accommodations,
                        num_questions:  diffGroups[index].num_questions,
                        questions: questionsArray,
                    }
                    return worksheetDataOutput;
                });
                console.log(allWorksheetData);
                setAPIMessage(allWorksheetData);
                setStatus("returned");
            };
          } catch (error) {
            setStatus("error");
            console.error('Error in handleUpload:', error);
          }
    };

    const finishAndSave = async (worksheetDataFinal) => {
        setWorksheetData(worksheetDataFinal);
        setStatus("finish");
    }

  return (
    <div className="base-container">
      <Navbar minimizedByDefault/>
      <div className="base-content-container">
        <div className="createWorksheet-container">
            { status === 'error' &&
                <p>There has been an error. Please refresh and try again!</p>
            }
            { status === 'loading' && 
                <LoadingBar duration="12"/>
            }
            { status === "initial" &&
                <ConfigureWorksheet 
                    file={file}
                    setFile={setFile}
                    imageEncodings={imageEncodings}
                    setImageEncodings={setImageEncodings}
                    inputType={inputType}
                    gradeLevels={gradeLevels} 
                    diffGroups={diffGroups} 
                    setDiffGroups={setDiffGroups} 
                    generalInfo={generalInfo} 
                    setGeneralInfo={setGeneralInfo} 
                    handleUpload={handleUpload}
                />
            }
            { status === "returned" && 
                <PreviewEditWorksheet 
                    worksheetDataInput={apiMessage} 
                    finishAndSave={finishAndSave}
                />
            }
            { status === "finish" && worksheetData && 
                <FinishWorksheet 
                    worksheetData={worksheetData} 
                    setDiffGroups={setDiffGroups} 
                    setStatus={setStatus}
                    />
            } 
        </div>
      </div>
    </div>
  );
}


export default CreateWorksheet;