import React from "react";
import PropTypes from 'prop-types';
import "./YourActivityCard.css";
import actionIcon from '../../../../../assets/Dashboard/YourActivities/actionIcon.svg';


function YourActivityCard({ activityData }) {
	return (
		<div className="yourActivityCard-container">
            <img className="yourActivityCard-thumbnail" src={activityData.thumbnail} alt="Activity Thumbnail" />
            <div className="yourActivityCard-info">
                <div className="yourActivityCard-description">
                    <h3 className="yourActivityCard-title">{activityData.activity_name}</h3>
                    <p className="yourActivityCard-lastEdited">Last Edited: {activityData.last_edited}</p>
                </div>
                <img className="yourActivityCard-action" src={actionIcon} alt="Action Dropdown"/>
            </div>
		</div>
	);
}

YourActivityCard.propTypes = {
	activityData: PropTypes.shape({
		id: PropTypes.string,
		activity_name: PropTypes.string,
		thumbnail: PropTypes.string,
		last_edited: PropTypes.string,
	}),
};

export default YourActivityCard;
