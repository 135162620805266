import React, { useState, useRef, useEffect } from "react";
// import * as pdfjsLib from 'pdfjs-dist';

import PropTypes from "prop-types";
import "./DragAndDrop.css";

import pdfFileIcon from "../../../../../assets/CreateWorksheet/pdfFileIcon.svg";
import exitIcon from "../../../../../assets/CreateWorksheet/deleteIcon.svg";
import PDFToBase64ImagesFunc from "../../utils/PDFtoImage";
import imageToBase64ImagesFunc from "../../utils/ImageFiletoImage";


function DragAndDrop({ file, setFile, setImageEncodings, inputType, fileImported }) {
    const [errorMessage, setErrorMessage ] = useState("");

    const browseInputFileRef = useRef();
    const dragInputFileRef = useRef();

    const errorCheckingAndSet = async (filesInput) => {
        const allowedFormats = ['.png', '.jpg', '.jpeg', '.pdf'];


        // check if some uploaded file is not in one of the allowed formats
        if (allowedFormats && !allowedFormats.some((format) => filesInput[0].name.toLowerCase().endsWith(format.toLowerCase()))) {
            console.log(`Only the following file formats are acceptable: ${allowedFormats.join(', ')}`);
            setErrorMessage(`Only the following file formats are acceptable: ${allowedFormats.join(', ')}`);
            return;
        }

        // check if the provided count prop is less than uploaded count of files
        if (filesInput.length !== 1) {
            console.log(`Only 1 file can be uploaded at a time`);
            setErrorMessage("Only 1 file can be uploaded at a time");
            return;
        }

        if (filesInput[0].name.toLowerCase().endsWith('.pdf')) {
            const base64Images = await PDFToBase64ImagesFunc(filesInput[0]);

            if (base64Images.length > 5) {
                console.log("Your PDF is too large. Try limiting the PDF to less than 5 pages");
                setErrorMessage("Your PDF is too large. Your PDF must be less than 5 pages");
                return;
            }
            setFile(filesInput[0]);
            setImageEncodings(base64Images);
            console.log("Setting file as: ", filesInput);
            console.log("Parsing PDF as: ", base64Images);
        }
        else {
            if (filesInput[0].size / 1000 / 1000 > 1) {
                console.log("Your image size is too large.");
                setErrorMessage("Your Image is too large")

                return;
            }

            const base64Image = await imageToBase64ImagesFunc(filesInput[0]);

            if (base64Image === "") {
                console.log("There was an error reading your image. Try again");
                setErrorMessage("There was an error reading your image. Try again")

                return;
            }
            
            setFile(filesInput[0])
            setImageEncodings([base64Image]);
            
            console.log("Setting file as: ", filesInput);
            console.log("Parsing Image as: ", [base64Image]);
        }

    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            errorCheckingAndSet(e.target.files);
        }
    };

    const handleBrowseFilesClick = () => {
        browseInputFileRef.current.click();
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
      
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.clearData();
        setErrorMessage("");

        const {files} = e.dataTransfer;

        errorCheckingAndSet(files)
    };

    useEffect(() => {
        const refCurrent = dragInputFileRef.current;
        dragInputFileRef.current.addEventListener('dragover', handleDragOver);
        dragInputFileRef.current.addEventListener('drop', handleDrop);
      
        return () => {
            refCurrent.removeEventListener('dragover', handleDragOver);
            refCurrent.removeEventListener('drop', handleDrop);
        };
    });

    return (
        <>
            <div 
                className={`dragAndDrop-upload-input-container ${!fileImported ? "input-invalid" : ""}`}
                ref={dragInputFileRef}
                // Setting visible here instead of conditional rendering due to ref error
                style={{"display": `${file ? "none" : "flex"}`}}
            >
                <div className="dragAndDrop-upload-input-info">
                    <h2 className="dragAndDrop-upload-input-heading">Drag and Drop to Upload Your {inputType === "worksheet" ? "Worksheet" : "Resource"}</h2>
                    <div className="dragAndDrop-upload-input-subSection">
                        <input 
                            id="dragAndDrop-fileInput" 
                            type="file" 
                            onChange={handleFileChange} 
                            ref={browseInputFileRef}
                            hidden/>
                        <p className="dragAndDrop-upload-input-subText" >or</p>
                        <input 
                            className="dragAndDrop-upload-input-inputElement" 
                            type="button" value="Browse Files" 
                            onClick={handleBrowseFilesClick} />
                    </div>
                    { errorMessage && <p className="dragAndDrop-upload-input-subText" style={{color: 'red'}}>{errorMessage}</p>}
                
                </div>
                
            </div>
            {file &&
                <div className="dragAndDrop-upload-preview-container">
                    <img 
                        className="dragAndDrop-upload-preview-icon"
                        src={pdfFileIcon} alt="PDF"/>

                    <div className="dragAndDrop-upload-preview-info">
                        <p className="dragAndDrop-upload-preview-text name">{file.name}</p>
                        <p className="dragAndDrop-upload-preview-text size">{file.size / 1000} KB</p>
                    </div>

                    <button 
                        className="dragAndDrop-upload-preview-img"
                        type="button" 
                        onClick={() => setFile(null)}>
                        <img 
                            className="dragAndDrop-upload-preview-img"
                            src={exitIcon} alt="Clear File"/>
                    </button>
                </div>
            }
        </>
        
  );
}

DragAndDrop.propTypes = {
    file: PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.number,
    }),
    setFile: PropTypes.func,
    setImageEncodings: PropTypes.func,
    inputType: PropTypes.string,
    fileImported: PropTypes.bool,
};

export default DragAndDrop;
