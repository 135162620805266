import React, { useState } from "react";
import PropTypes from 'prop-types';

import { closestCorners, DndContext, MeasuringStrategy } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { v4 as uuidv4 } from 'uuid';

import SortableItem from "./SortableItem";
import "./EditWorksheet.css";
import { singleWorksheetDataPropType } from "../../CreateWorksheetConstants";

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */

function EditWorksheet({ worksheetIndex, worksheetData, setWorksheetData }) {

    const [removeMode, setRemoveMode] = useState(false);

    worksheetData.questions = worksheetData.questions.map((question) => {
        if (!question.id) {
            // Only assign an ID if it doesn't already have one
            return {
                ...question,
                id: uuidv4(), // Generate a unique id
            };
        }
        return question; // Keep the existing ID if it already has one
    });

    function handleAddQuestion() {
        console.log("hi");

        const newQuestions = [
            { id: uuidv4(), question_text: '', correct_answer: '', incorrect_answers: [] },
            ...worksheetData.questions
        ];

        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions = newQuestions;
            return newWorksheetData;
        });
    }

    function handleRemoveQuestion(index) {
        console.log("hi");
        // Trigger the animation by updating the state
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            const newQuestions = Array.from(newWorksheetData[worksheetIndex].questions);
            newQuestions.splice(index, 1);
            newWorksheetData[worksheetIndex].questions = newQuestions;
            return newWorksheetData;
        });
    }

    function handleRemove() {
        setRemoveMode(!removeMode);
        console.log(removeMode);
    }

    function handleQuestionChange(e, questionIndex) {
        console.log("hi");
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions[questionIndex].question_text = e.target.value;
            return newWorksheetData;
        });
    }

    function handleCorrectAnswerChange(e, questionIndex) {
        console.log("hi");
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions[questionIndex].correct_answer = e.target.value;
            return newWorksheetData;
        });
    }

    function handleIncorrectAnswerChange(e, questionIndex, answerIndex) {
        console.log("hi");
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions[questionIndex].incorrect_answers[answerIndex] = e.target.value;
            return newWorksheetData;
        });
    }

    const handleOnDragEnd = (event) => {

        const { active, over } = event;

        console.log("active:", active);
        console.log("over:", over);

        if (active.id !== over.id) {

            console.log("set new data");

            setWorksheetData((prevData) => {
                const oldIndex = prevData[worksheetIndex].questions.findIndex(item => item.id.toString() === active.id);
                const newIndex = prevData[worksheetIndex].questions.findIndex(item => item.id.toString() === over.id);
    
                const newQuestions = arrayMove(prevData[worksheetIndex].questions, oldIndex, newIndex);
                const newWorksheetData = [...prevData];
                newWorksheetData[worksheetIndex].questions = newQuestions; 
    
                return newWorksheetData;
            });
        }
    }

    const measuringConfig = {
        droppable: {
          strategy: MeasuringStrategy.Always
        }
      };

    return (
        <DndContext onDragEnd={handleOnDragEnd} collisionDetection={closestCorners} modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]} measuring={measuringConfig}>
            <div className="editWorksheet-container">
                <div className="editWorksheet-header">
                    <div>
                        <h1 className="editWorksheet-title">Edit Questions</h1>
                        <p className="editWorksheet-subTitle">Edit the generated questions and preview.</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button
                            type="button"
                            className="editWorksheet-button"
                            onClick={handleAddQuestion}
                        > Add </button>
                        <button
                            type="button"
                            className={`editWorksheet-button ${removeMode ? 'active' : ''}`}
                            onClick={handleRemove}
                            id="removeButton"
                        >Remove</button>
                    </div>

                </div>

                <div className="editWorksheet-questions-container">

                    <SortableContext items={worksheetData.questions.map(q => q.id.toString())} strategy={verticalListSortingStrategy}>
                        {worksheetData.questions.map((question, questionIndex) => (
                            <SortableItem
                            key={question.id.toString()}
                            id={question.id}
                            removeMode={removeMode}
                        >
                            {({ listeners, removeMode }) => (
                                <div className="editWorksheet-listItem-container">
                                    {
                                        removeMode && (
                                            <button
                                                className="editWorksheet-listItem-handle-remove"
                                                type="button"
                                                onClick={() => handleRemoveQuestion(questionIndex)}>✖</button>
                                        )
                                    }
                                    {
                                        !removeMode && (
                                            <div className="editWorksheet-listItem-handle" {...listeners}>
                                                <br /><br /><br />_
                                            </div>
                                        )
                                    }
                                    <div
                                        className="editWorksheet-question-container"
                                        key={`question-${question.id + 1}`}
                                    >
                                        <label
                                            className="editWorksheet-question-label question"
                                            htmlFor="editWorksheet-question-questionText"
                                        >Question {questionIndex + 1}:</label>
                                        <textarea
                                            id="editWorksheet-question-questionText"
                                            name="editWorksheet-question-questionText"
                                            className="editWorksheet-question-textArea"
                                            value={question.question_text}
                                            onChange={(e) => handleQuestionChange(e, questionIndex)} />
                                        <label
                                            className="editWorksheet-question-label"
                                            htmlFor="editWorksheet-question-correctAns"
                                        >Correct Answer:</label>
                                        <input
                                            type="text"
                                            className="editWorksheet-question-textInput"
                                            id="editWorksheet-question-correctAns"
                                            name="editWorksheet-question-correctAns"
                                            value={question.correct_answer}
                                            onChange={(e) => handleCorrectAnswerChange(e, questionIndex)}
                                        />
                                        {worksheetData.worksheet_type === "multiple choice" && (
                                            <>
                                                <p className="editWorksheet-question-label">Incorrect Answers:</p>
                                                <div className="editWorksheet-question-wrongAns-container">
                                                    {question.incorrect_answers.map((ans, answerIndex) => (
                                                        <input
                                                            type="text"
                                                            className="editWorksheet-question-textInput"
                                                            id={`editWorksheet-question-wrongAns-${answerIndex + 1}`}
                                                            key={`editWorksheet-question-wrongAns-${answerIndex + 1}`}
                                                            name="editWorksheet-question-wrongAns"
                                                            value={ans}
                                                            onChange={(e) => handleIncorrectAnswerChange(e, questionIndex, answerIndex)}
                                                        />
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    
                                </div>
                            )}
                        </SortableItem>
                        )
                        )}
                    </SortableContext>
                </div>
            </div>
        </DndContext>
    );
}

EditWorksheet.propTypes = {
    worksheetIndex: PropTypes.number,
    setWorksheetData: PropTypes.func,
    worksheetData: singleWorksheetDataPropType,
};

export default EditWorksheet;
