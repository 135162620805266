// OBJECTS
export const emptyDifferentiaionGroup = {
    grade_level: "",
    num_questions: "",
    accommodations: {
        reading: {
            active: false,
            reading_level: "",
        },
        MLL: {
            active: false,
            type: "",
            language: "",
        },
        IEP: {
            active: false,
            accommodations: {
                font_size: 12,
                line_spacing: 1.5,
            },
        },
        DOK: {
            active: false,
            level: "",
        },
    },
};

export const emptyDifferentiaionGroups = [structuredClone(emptyDifferentiaionGroup)];

export const emptyGeneralInfo = {
    topic: "", 
    worksheet_name: "", 
    grade_level: "", 
    standards: []
};