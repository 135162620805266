import PropTypes from 'prop-types';

// Prop Types for Differentiation Groups

// Catch all prop type for any type of accomodations
const accommodationPropType = PropTypes.shape({
    active: PropTypes.bool.isRequired,
    reading_level: PropTypes.string,
    type: PropTypes.string,
    language: PropTypes.string,
    accommodations: PropTypes.shape({
        font_size: PropTypes.number,
        line_spacing: PropTypes.number,
    }),
    level: PropTypes.string,
});

const accommodationsPropType = PropTypes.shape({
    reading: accommodationPropType.isRequired,
    MLL: accommodationPropType.isRequired,
    IEP: accommodationPropType.isRequired,
    DOK: accommodationPropType.isRequired,
});

const diffGroupPropType = PropTypes.shape({
    grade_level: PropTypes.string.isRequired,
    num_questions: PropTypes.string.isRequired,
    accommodations: accommodationsPropType.isRequired,
});

export const diffGroupsPropType = PropTypes.arrayOf(diffGroupPropType).isRequired;

// GENERAL INFO 
export const generalInfoPropType = PropTypes.shape({
    topic: PropTypes.string.isRequired,
    worksheet_name: PropTypes.string.isRequired,
    grade_level: PropTypes.string.isRequired,
    standards: PropTypes.arrayOf(
        PropTypes.shape({
            standard_code: PropTypes.string,
            description: PropTypes.string,
        })
    ).isRequired,
});

// QUESTIONS DATA PROPS
export const questionPropType = PropTypes.shape({
    question_type: PropTypes.string,
    question_text: PropTypes.string.isRequired,
    correct_answer: PropTypes.string,
    incorrect_answers: PropTypes.arrayOf(PropTypes.string),
}).isRequired;

// WORKSHEET DATA PROPS
export const singleWorksheetDataPropType = PropTypes.shape({
    worksheet_title: PropTypes.string.isRequired,
    worksheet_type: PropTypes.string.isRequired,
    grade_level: PropTypes.string.isRequired,
    accommodations: accommodationsPropType.isRequired,
    num_questions: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(questionPropType).isRequired,
})

export const allWorksheetDataPropType = PropTypes.arrayOf(singleWorksheetDataPropType).isRequired

// GRADE LEVEL SELECTION OPTIONS
export const gradeLevels = [
    {
        id: 1,
        name: "Grade 1"
    },
    {
        id: 2,
        name: "Grade 2"
    },
    {
        id: 3,
        name: "Grade 3"
    },
    {
        id: 4,
        name: "Grade 4"
    },
    {
        id: 5,
        name: "Grade 5"
    },
    {
        id: 6,
        name: "Grade 6"
    },
    {
        id: 7,
        name: "Grade 7"
    },
    {
        id: 8,
        name: "Grade 8"
    },
    {
        id: 9,
        name: "Grade 9"
    },
    {
        id: 10,
        name: "Grade 10"
    },
    {
        id: 11,
        name: "Grade 11"
    },
    {
        id: 12,
        name: "Grade 12"
    }
];

const gradeLevelPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const gradeLevelsPropType = PropTypes.arrayOf(gradeLevelPropType).isRequired;


// SLIDE SELECTOR CONSTANTS AND PROP TYPES

const slideSelectorOptionPropType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    descriptor: PropTypes.string.isRequired,
});

export const slideSelectorOptionsPropType = PropTypes.arrayOf(slideSelectorOptionPropType).isRequired;

// Reading Level selection options
export const readingLevels = [
    {
        title: "Grade 1",
        descriptor: "150 - 400 Lexile",
    },
    {
        title: "Grade 2-3",
        descriptor: "400 - 850 Lexile",
    },
    {
        title: "Grade 4-5",
        descriptor: "850 - 1100 Lexile",
    },
    {
        title: "Grade 6-8",
        descriptor: "1000 - 1300 Lexile",
    },
    {
        title: "Grade 9-10",
        descriptor: "1200 - 1400 Lexile",
    },
    {
        title: "Grade 11-12",
        descriptor: "1300 - 1450 Lexile",
    },
];

// Font Size selection options
export const fontSizes = [
    {
        title: "Small",
        descriptor: "10",
    },
    {
        title: "Default",
        descriptor: "12",
    },
    {
        title: "Large",
        descriptor: "14",
    },
    {
        title: "Larger",
        descriptor: "16",
    },
    {
        title: "Largest",
        descriptor: "18",
    },
];

// Font Size selection options
export const lineSpacing = [
    {
        title: "Small",
        descriptor: "1.15",
    },
    {
        title: "Default",
        descriptor: "1.5",
    },
    {
        title: "Large",
        descriptor: "1.75",
    },
    {
        title: "Largest",
        descriptor: "2",
    }
];
