import React from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import "./FinishWorksheet.css";
import PreviewWorksheet from "./components/PreviewWorksheet";

import { emptyDifferentiaionGroups } from "../CreateWorksheetObjects";
import { allWorksheetDataPropType } from "../CreateWorksheetConstants";


function FinishWorksheet({ worksheetData, setDiffGroups, setStatus }) {

    const navigate = useNavigate();

    const handleExit= () => {
        setDiffGroups(structuredClone(emptyDifferentiaionGroups));
        navigate('/home');
    }

    function prettyPrintActiveAccommodations(accommodations) {
        let result = '';
    
        if (accommodations.reading.active) {
            result += `Reading Level: ${accommodations.reading.reading_level}, `;
        }
        if (accommodations.MLL.active) {
            result += `Multilingual Learner: ${accommodations.MLL.type}`;
            if (accommodations.MLL.type === "Full Translation" || accommodations.MLL.type === "Word-based Translation") {
                result += ` (${accommodations.MLL.language})`;
            }
            result += ', ';
        }
        if (accommodations.IEP.active) {
            const accommodationEntries = Object.entries(accommodations.IEP.accommodations)
                .map(([key, value]) => `${key.replace('_', ' ')}: ${value}`)
                .join(', ');
            
            result += `IEP: ${accommodationEntries}, `;
        }
        if (accommodations.DOK.active) {
            result += `DOK Level: ${accommodations.DOK.level}, `;
        }
    
        // If no accommodations are active
        if (result === '') {
            return "None";
        }
        result = result.substring(0, result.length - 2);
    
        return result;
    }

    return (
        <div className="finishWorksheet-container">
            <div className="finishWorksheet-options-container">
                <h1
                    className="finishWorksheet-options-title"
                    >{worksheetData[0].worksheet_title}</h1>
                <p>{worksheetData.length} worksheets in this set.</p>
                <div className="finishWorksheet-options-worksheet-container">
                { worksheetData.map((worksheet, index) =>
                    <div key={`worksheet-${index + 1}-description`}>
                        <p 
                            className="finishWorksheet-options-worksheet-title"><b>Worksheet {index + 1}:</b></p>
                        <p
                            className="finishWorksheet-options-worksheet-description">
                            Grade Level: {`${worksheet.grade_level}`}</p>
                        <p
                            className="finishWorksheet-options-worksheet-description">
                            Number of Questions: {worksheet.num_questions}</p>
                        <p
                            className="finishWorksheet-options-worksheet-description">
                            Customizations: {prettyPrintActiveAccommodations(worksheet.accommodations)} </p>
                    </div>
                )}
                </div>
                <div
                    style={{display: "flex", gap: "10px", marginTop: "auto", justifyContent: "center"}}
                >
                    <button
                        type="button"
                        className="finishWorksheet-saveExit-button"
                        onClick={handleExit}
                    >
                        Exit
                    </button>
                    <button
                        type="button"
                        className="finishWorksheet-saveExit-button"
                        onClick={() => setStatus("returned")}
                    >
                        Back
                    </button>
                </div>
            </div>
            <div className="finishWorksheet-preview-container">
                { worksheetData.map((worksheet, index) =>
                    <div 
                        className="finishWorksheet-preview-worksheet"
                        key={`worksheet-${index + 1}-description`}>
                        <p className="previewEditWorksheet-title">Worksheet {index + 1} of {worksheetData.length}</p>
                        <p className="previewEditWorksheet-subTitle">
                            {`${worksheet.grade_level}, `} 
                            {worksheet.num_questions} Questions<br/>
                            {prettyPrintActiveAccommodations(worksheet.accommodations)} 
                            </p>
                        <PreviewWorksheet 
                            worksheetIndex={index} 
                            worksheetData={worksheet}
                            enableExport
                            />
                    </div>
                )}
            </div>
        </div>
        
    );
}

FinishWorksheet.propTypes = {
    worksheetData: allWorksheetDataPropType,
    setDiffGroups: PropTypes.func,
    setStatus: PropTypes.func,
}


export default FinishWorksheet;