import React, { useState } from "react";
import "./YourActivitiesDashboard.css";
import YourActivitiesDashboardHeader from "./components/YourActivitiesDashboardHeader";
import YourActivityCard from "./components/YourActivityCard";
// import thumbnail1 from '../../../../assets/Dashboard/YourActivities/thumbnail_example1.png';
// import thumbnail2 from '../../../../assets/Dashboard/YourActivities/thumbnail_example2.png';
// import thumbnail3 from '../../../../assets/Dashboard/YourActivities/thumbnail_example3.png';
import noActivitiesYet from '../../../../assets/Dashboard/YourActivities/noActivitiesImg.png';


// const yourActivitesExamples = [
//     {
//         activity_name: "Unit 2: Mitosis (Grade 7)",
//         activity_type: "game",
//         activity_template: "quiz_off",
//         last_edited: "July 3rd 2024",
//         thumbnail: thumbnail1
//     },
//     {
//         activity_name: "Unit 1: WW1 (Grade 8)",
//         activity_type: "group",
//         activity_template: "jigsaw",
//         last_edited: "July 2nd 2024",
//         thumbnail: thumbnail2
//     },
//     {
//         activity_name: "Unit 1: Prose Fiction (Grade 8)",
//         activity_type: "worksheet",
//         activity_template: "cut_paste",
//         last_edited: "July 1st 2024",
//         thumbnail: thumbnail3
//     },
//     {
//         activity_name: "Unit 1: WW1 (Grade 8)",
//         activity_type: "group",
//         activity_template: "jigsaw",
//         last_edited: "July 2nd 2024",
//         thumbnail: thumbnail2
//     },
//     {
//         activity_name: "Unit 1: Prose Fiction (Grade 8)",
//         activity_type: "worksheet",
//         activity_template: "cut_paste",
//         last_edited: "July 1st 2024",
//         thumbnail: thumbnail3
//     },
//     {
//         activity_name: "Unit 2: Mitosis (Grade 7)",
//         activity_type: "game",
//         activity_template: "quiz_off",
//         last_edited: "July 3rd 2024",
//         thumbnail: thumbnail1
//     },
//     {
//         activity_name: "Unit 1: WW1 (Grade 8)",
//         activity_type: "group",
//         activity_template: "jigsaw",
//         last_edited: "July 2nd 2024",
//         thumbnail: thumbnail2
//     },
//     {
//         activity_name: "Unit 1: Prose Fiction (Grade 8)",
//         activity_type: "worksheet",
//         activity_template: "cut_paste",
//         last_edited: "July 1st 2024",
//         thumbnail: thumbnail3
//     },
//     {
//         activity_name: "Unit 2: Mitosis (Grade 7)",
//         activity_type: "game",
//         activity_template: "quiz_off",
//         last_edited: "July 3rd 2024",
//         thumbnail: thumbnail1
//     }
// ];

function YourActivitiesDashboard() {
    const [allActivites] = useState([]);
    const [filteredActivities, setFilteredActivities ] = useState([]);

	return (
		<div 
            className={`yourActivitiesDashboard-container ${filteredActivities.length === 0 ? 'empty' : ''}`}>
			<YourActivitiesDashboardHeader allActivities={allActivites} setFilteredActivities={setFilteredActivities}/>

            <div className="yourActivitiesDashboard-grid">
                {filteredActivities.map((activity) => (
                    <YourActivityCard activityData={activity} />
                ))}
            </div>

            {   filteredActivities.length === 0 &&
                <div className="yourActivitiesDashboard-noActivities-container">
                    <img 
                        className="yourActivitiesDashboard-noActivities-img"
                        src={noActivitiesYet} alt="No Activities Yet"/>
                    <p className="yourActivitiesDashboard-noActivities-text">
                        You don&apos;t have any worksheets yet! <br/>
                    Import or choose a template to get started.</p>

                </div>
            }
		</div>
	);
}


export default YourActivitiesDashboard;
