import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./Dashboard.css";
import Navbar from "../../components/NavBar/NavBar";
import SiteHeader from "../../components/SiteHeader/SiteHeader";
import TemplatesDashboard from "./components/TemplatesSection/TemplatesDashboard";
import YourActivitiesDashboard from "./components/YourActivitiesSection/YourActivitesDashboard";
import CreateActivityPopup from "./components/CreateActivityPopup";


function Dashboard() {
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [popupInputType, setPopupInputType] = useState("");
	const [popupTemplate, setPopupTemplate] = useState("");

	const openPopup = (startedFromInputType, selectedOption) => {
		setIsPopupVisible(true);
		if (startedFromInputType) {
			setPopupInputType(selectedOption);
			setPopupTemplate("");
		}
		else {
			setPopupInputType("");
			setPopupTemplate(selectedOption);

		}
		
		console.log("opening popup");
	};

	const closePopup = () => {
		setIsPopupVisible(false);
		setPopupInputType("");
		setPopupTemplate("");
		console.log("closing popup");
	}

	return (
		<div className="base-container">
			<Navbar/>
			<div className="base-content-container">
				<SiteHeader/>
				<div className="dashboard-container">
					<TemplatesDashboard openPopup={openPopup}/>
					<YourActivitiesDashboard/>
				</div>
			</div>
			{isPopupVisible && <CreateActivityPopup closePopup={closePopup} inputType={popupInputType} template={popupTemplate}/>}
		</div>
	);
}

export default Dashboard;
