import React from 'react';
import PropTypes from 'prop-types';
import { TextWrap, ViewWrap } from '../WorksheetWrappers';

import { singleWorksheetDataPropType } from '../../pages/CreateWorksheet/CreateWorksheetConstants';

import { containsChinese } from '../ChineseAccessability';


function random(seed) {
    const x = Math.sin(seed+1) * 10000;
    return x - Math.floor(x);
}

// Create Document Component
function AnswersBox({ worksheetData, config, isPdf }) {

    const correctAnswersUnshuffled = worksheetData.questions.map(question => question.correct_answer);;

    function shuffle(array) {
        for (let i = array.length - 1; i > 0; i -= 1) {
            const j = Math.floor(random(new Date().getHours() + 1) * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const correctAnswers = shuffle(correctAnswersUnshuffled);

    return (
        <ViewWrap
            isPdf={isPdf}
            styleProps={ config }
            styleName="answersHeader"
            elementKey="answersHeader"
        >
            {correctAnswers.map((answer, index) => (
                <TextWrap
                    key={`answer-${index + 1}`}
                    isPdf={isPdf}
                    styleProps={ config }
                    styleNames={['answerText', containsChinese(answer) ? 'chineseText' : '']}
                    elementKey={`answer-${index + 1}`}
                >
                    {answer}
                </TextWrap>
            ))}
        </ViewWrap>
    );
}

AnswersBox.propTypes = {
    worksheetData: singleWorksheetDataPropType,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
    })
}

export default AnswersBox;

