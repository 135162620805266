import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';

import {
    ClassicEditor,
    Bold,
    Code,
    Essentials,
    GeneralHtmlSupport,
    Italic,
    Paragraph,
    Strikethrough,
    Subscript,
    Superscript,
    Underline,
    Undo
} from 'ckeditor5';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '@ckeditor/ckeditor5-theme-lark/theme/index.css';

import ReactDOMServer from 'react-dom/server';
import "./Editor.css"

const editorConfig = {
    toolbar: {
        items: [
            'undo',
            'redo',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'code',
        ],
        shouldNotGroupWhenFull: true
    },
    plugins: [
        Bold,
        Code,
        Essentials,
        GeneralHtmlSupport,
        Italic,
        Paragraph,
        Strikethrough,
        Subscript,
        Superscript,
        Underline,
        Undo
    ],
    fontSize: {
        options: [10, 'default', 14, 16, 18, 20, 22],
        supportAllValues: false
    },
    htmlSupport: {
        allow: [
            {
                name: /^.*$/,
                styles: true,
                attributes: true,
                classes: true
            }
        ]
    },
    image: {
        toolbar: ['imageTextAlternative', '|', 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|', 'resizeImage']
    },
    link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
            toggleDownloadable: {
                mode: 'manual',
                label: 'Downloadable',
                attributes: {
                    download: 'file'
                }
            }
        }
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
    }
};

// This should fix the issue of embedded <TextWrap> classes which are fine for react-pdf but not html
// const removeEmbeddedClosingParagraph = (htmlContent) => {
    
//     // Regex to match a <p> tag that contains another <p> tag directly
//     const regex = /<p([^>]*)>([^<]*)(<p[^>]*>[\s\S]*?<\/p>)([^<]*)<\/p>/g;

//     // Replace the outer closing </p> with nothing, effectively removing it
//     return htmlContent.replace(regex, '<p$1>$2$3$4');
// };

function Editor({doc, onExport}) {

    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const [editorContent, setEditorContent] = useState(ReactDOMServer.renderToStaticMarkup(doc));

    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorContent(data);
    };

    return (
        
        <div>
            <div className="main-container">
                <div className="editor-container editor-container_classic-editor" ref={editorContainerRef}>
                    <div className="editor-container__editor">
                        <div ref={editorRef}>{isLayoutReady && <CKEditor
                            editor={ClassicEditor}
                            data={editorContent}
                            onChange={handleEditorChange}
                            config={editorConfig} />}
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" className="testButton" onClick={() => onExport(editorContent)}> Export Me!! </button>
        </div>    
            
    );
}

Editor.propTypes = {
    doc: PropTypes.node.isRequired,
    onExport: PropTypes.func
}

export default Editor;
