import React from 'react';

import { getAuth } from 'firebase/auth'; // Firebase v9+
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions';
import { AnalyticsProvider, AuthProvider, FunctionsProvider, useFirebaseApp } from 'reactfire';

import App from './App';


function FirebaseAppWrapper() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

  // initialize Auth with the normal Firebase SDK functions
  const auth = getAuth(app);

  const functions = getFunctions(app);

  const analytics = getAnalytics(app);

  return (
    <AuthProvider sdk={auth}>
      <FunctionsProvider sdk={functions}>
        <AnalyticsProvider sdk={analytics}>
          <App />
        </AnalyticsProvider>
      </FunctionsProvider>
    </AuthProvider>
  );
}

export default FirebaseAppWrapper;