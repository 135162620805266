import React from 'react';
import PropTypes from 'prop-types';
import { EmbeddedTextWrap, TextWrap, ViewWrap } from '../WorksheetWrappers';

import { questionPropType } from '../../pages/CreateWorksheet/CreateWorksheetConstants';

import { splitText } from '../ChineseAccessability';

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};


// Create Document Component
function MultipleChoice({ question, config, isPdf }) {

    const combinedAnswers = (q) => {
        const cA = [
            q.correct_answer,
            ...q.incorrect_answers.map((ans) => (ans)),
        ];
        return shuffleArray(cA);
    };


    return (

        <ViewWrap isPdf={isPdf}
            styleName="answerOptionsContainer"
            styleProps={ config }>
            {combinedAnswers(question).map((ans, index) => (
                <EmbeddedTextWrap
                    isPdf={isPdf}
                    key={`ansOption-${index + 1}`}
                    styleName = ''
                    styleProps={ config }>
                    
                    <TextWrap
                            isPdf={isPdf}
                            key={`answerLetter-${index + 1}`}
                            styleProps={ config }
                            styleNames={['answerOption', 'inlineText']}
                        >
                            {` ${String.fromCharCode(97 + index)}) `}
                    </TextWrap>
                    {splitText(ans).map((part, partIndex) => (
                        
                        <TextWrap
                            isPdf={isPdf}
                            key={`answerSegment-${index + 1}-${partIndex + 1}`}
                            styleProps={ config }
                            styleNames={['answerOption', 'inlineText', part.isChinese ? 'chineseText' : '']}
                        >
                            {part.text}
                        </TextWrap>

                    ))}
                </EmbeddedTextWrap>
            ))}
        </ViewWrap>

    );
}

MultipleChoice.propTypes = {
    question: questionPropType,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
    })
}

export default MultipleChoice;
