import React, { useState, createContext, useContext, useMemo, useEffect } from "react";
import PropTypes from 'prop-types';
import "./NavBar.css";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useAuth } from 'reactfire';

// Icon Imports
import logo from "../../assets/wizlab-logo.svg";
import logoIcon from "../../assets/wizlab-staff.svg";
import homeIcon from "../../assets/NavBar/nav-home.svg";
import myLabIcon from "../../assets/NavBar/nav-myLab.svg";
import exploreIcon from "../../assets/NavBar/nav-explore.svg";
import statisticsIcon from "../../assets/NavBar/nav-statistics.svg";
import communityIcon from "../../assets/NavBar/nav-community.svg";
import helpIcon from "../../assets/NavBar/nav-help.svg";
import logoutIcon from "../../assets/NavBar/nav-logout.svg";
import minimzeIcon from "../../assets/NavBar/nav-minimize.png";

export const NavbarContext = createContext();

// Provider for NavBar isMinimized Context
export function NavbarProvider ({ children }) {
    const [isMinimized, setIsMinimized] = useState(false);

	const value = useMemo(() => ({ isMinimized, setIsMinimized }), [isMinimized, setIsMinimized]);

    return (
        <NavbarContext.Provider value={value}>
            {children}
        </NavbarContext.Provider>
    );
};

NavbarProvider.propTypes = {
    children: PropTypes.element.isRequired,
}

function Navbar({ minimizedByDefault = false}) {

	const mainNavItems = [
		{ icon: homeIcon, label: "Home", navLink: "/home" },
		{ icon: myLabIcon, label: "My Lab", navLink: "/my-lab" },
		{ icon: statisticsIcon, label: "Statistics", navLink: "/statistics" },
		{ icon: exploreIcon, label: "Explore", navLink: "/explore" }
	];

	const footerItems = [
		{ icon: communityIcon, label: "Community", navLink: "/community" },
		{ icon: helpIcon, label: "Help", navLink: "/help" }
	];

	const { isMinimized, setIsMinimized } = useContext(NavbarContext);

	const auth = useAuth();

	const navigate = useNavigate();

	// Sign Out
	const signOut = (authObj) => {
		authObj.signOut()
		.then(() => {
			console.log("redirect");
			navigate("/home");
		});
	};

	// Handle Toggle Minimize
    const handleMinimize = () => {
        setIsMinimized(prev => !prev);
    };

	// Set minimized state only on initial render
	useEffect(() => {
		if (minimizedByDefault) {
			setIsMinimized(true);
		}
	}, [minimizedByDefault, setIsMinimized]);


	return (
		<nav className={`nav-container ${isMinimized ? "minimized" : ""}`}>
			{/* Wizlab Logo */}
			<Link to="/home">
				{ !isMinimized && 
					<img className="nav-logo" src={logo} alt="Logo"/> 
				}
				{ isMinimized && 
					<img className="nav-logo minimized" src={logoIcon} alt="Logo"/>
				}
			</Link>

			{/* Main Nav Items */}
			<div className="nav-items">
				{mainNavItems.map((item) => (
					<div key={item.label}>
						<NavLink className="nav-item" to={item.navLink}>
							{({ isActive }) => (
								<>
								<div className="nav-item-clickable">
									<img className="nav-item-icon" src={item.icon} alt={`${item.label} Icon`} />
									{ !isMinimized && <span className="nav-item-text">{item.label}</span> }
								</div>
								{isActive && <div className="nav-item-active"/>}
								</>
							)}
						</NavLink>
					</div>
				))}
			</div>

			{/* Nav Footer */}
			<div className="nav-footer">
				{footerItems.map((item) => (
					<div key={item.label}>
						<NavLink className="nav-item" to={item.navLink}>
							<div className="nav-footer-clickable">
								<img className="nav-item-icon" src={item.icon} alt={`${item.label} Icon`} />
								{ !isMinimized && <span className="nav-item-text">{item.label}</span> }
							</div>
						</NavLink>
					</div>
				))}
				<div className="nav-footer-item">
					<button type="button" className="nav-footer-clickable" onClick={() => signOut(auth)}>
							<img className="nav-item-icon" src={logoutIcon} alt="logout" />
							{ !isMinimized && <span className="nav-item-text">Logout</span> }
					</button>
				</div>
			</div>
			<button 
				type="button"
				className={isMinimized ? "nav-footer-minimize minimized" : "nav-footer-minimize"} 
				onClick={handleMinimize}
			>
				<img className={`nav-footer-minimize-icon ${isMinimized ? "flip" : ""}`}
					src={minimzeIcon} alt="minimize"/>
			</button>
			
		</nav>
	);
}

Navbar.propTypes = {
    minimizedByDefault: PropTypes.bool,
}

export default Navbar;
