import React from 'react';
import PropTypes from 'prop-types';
import { PageWrap, ImageWrap, TextWrap, ViewWrap, EmbeddedTextWrap } from './WorksheetWrappers';
import { splitText } from './ChineseAccessability';

import WorksheetHeaderBanner from './assets/WorksheetHeaderBanner.png';
import WorksheetFooterBanner from './assets/WorksheetFooterBanner.png';

import { singleWorksheetDataPropType } from '../pages/CreateWorksheet/CreateWorksheetConstants';

const FONT_SIZE = 12;
const LINE_SPACING = 1.5;

// Create Page Component
function AnswerKey({ worksheetData, worksheetIndex, isPdf, config = { fontSize: FONT_SIZE, lineSpacing: LINE_SPACING } }) {

    return (
        <PageWrap
            isPdf={isPdf}
            size="A4"
            // style={worksheetData.accommodations.IEP.accomodations.includes('Dyslexia') ? styles.dyslexicMode : styles.page}
            styleProps={config}
        >
            <ViewWrap isPdf={isPdf} styleProps={config} styleName="header" elementKey="key-header">
                <ImageWrap isPdf={isPdf} styleProps={config} styleName="headerBanner" src={WorksheetHeaderBanner} />
            </ViewWrap>
            <ViewWrap isPdf={isPdf} styleProps={config} styleName="content" elementKey="key-content">
                <TextWrap
                    isPdf={isPdf}
                    styleProps={config}
                    styleNames={['h1']}
                    elementKey="answerKey"
                >
                    Answer Key

                </TextWrap>

                {worksheetData.questions.map((question, questionIndex) => (
                    <ViewWrap isPdf={isPdf} styleProps={config}>
                        <ViewWrap
                            isPdf={isPdf}
                            styleName="questionContainer" styleProps={config}
                            key={`answer-${worksheetIndex}-${questionIndex + 1}`}
                            wrap={false}
                        >
                            <EmbeddedTextWrap
                                isPdf={isPdf}
                                elementKey={`question-text-${questionIndex + 1}`}
                                styleProps={config}
                                styleName=''
                            >
                                {splitText(`${questionIndex + 1}. ${question.question_text}`).map((part, index) => (
                                    <TextWrap
                                        isPdf={isPdf}
                                        styleProps={config}
                                        styleNames={['questionText', 'inlineText', part.isChinese ? 'chineseText' : '']}
                                        elementKey={`answer-answerSegment-${index + 1}`}
                                    >
                                        {part.text}
                                    </TextWrap>
                                ))}
                            </EmbeddedTextWrap>
                        </ViewWrap>

                        <ViewWrap
                            isPdf={isPdf}
                            styleName="questionContainer" styleProps={config}
                            key={`answer-${worksheetIndex}-${questionIndex + 1}`}
                            wrap={false}
                        >
                            <EmbeddedTextWrap isPdf={isPdf} styleName="questionText" styleProps={config}>

                                {splitText(question.correct_answer).map((part, index) => (
                                    <TextWrap
                                        isPdf={isPdf}
                                        styleProps={config}
                                        styleNames={['questionText', 'inlineText', part.isChinese ? 'chineseText' : '']}
                                        elementKey={`answer-answer-answerSegment-${index + 1}`}
                                    >
                                        {part.text}
                                    </TextWrap>
                                ))}
                            </EmbeddedTextWrap>
                        </ViewWrap>
                    </ViewWrap>

                ))}
            </ViewWrap>
            <ViewWrap isPdf={isPdf} styleProps={config} styleName="footer" elementKey="key-footer">
                <ImageWrap isPdf={isPdf} styleProps={config} styleName="footerBanner" src={WorksheetFooterBanner} />
            </ViewWrap>
        </PageWrap>
    );
}

AnswerKey.propTypes = {
    worksheetIndex: PropTypes.number,
    worksheetData: singleWorksheetDataPropType,
    isPdf: PropTypes.bool,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
        linesBetweenQuestions: PropTypes.number,
        answerKey: PropTypes.bool
    })
};

export default AnswerKey;

