import React, {useState} from "react";
import { PDFViewer } from '@react-pdf/renderer';
import Editor from "./Editor";
import Navbar from "../../components/NavBar/NavBar";
import FillInTheBlankWorksheet from "../../WorksheetTemplates/FillInTheBlankWorksheet";
import sampleData from "./sampleData.json"
import "./EditWorksheet.css"

import { UpdatedComponents } from "../../WorksheetTemplates/WorksheetWrappers";

const a4PageStyle = {
    width: '595px',  // A4 width at 72 DPI
    margin: '0 auto',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
};

const pdfDocument = <div style={a4PageStyle} className="whole-worksheet"><FillInTheBlankWorksheet worksheetData={sampleData} worksheetIndex={1} isPdf={false} /></div>;

function EditWorksheetTest() {


    const [updatedHtml, setUpdatedHtml] = useState("");

    const onExport = (html) => {
        setUpdatedHtml(html);
    };

    return (
        <div className="base-container">
            <Navbar/>
            <div className="base-content-container">
                <Editor doc={pdfDocument} onExport = {onExport}/>
                <div style={{display: 'flex'}}>
                    <PDFViewer style={{ minWidth: '52%', height: '150vh' }}>
                        <UpdatedComponents updatedHtml={updatedHtml} isPdf styleProps={{fontSize: 12, lineSpacing:1.5}}/>
                    </PDFViewer>
                    <div style={a4PageStyle}>
                        <UpdatedComponents updatedHtml={updatedHtml} isPdf={false} styleProps={{fontSize: 12, lineSpacing:1.5}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default EditWorksheetTest;
