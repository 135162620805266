import React from "react";
import PropTypes from "prop-types";

import "./LoadingBar.css";


function LoadingBar({ duration }) {
  return (
    <div className="loadingBar-container">
        <div className="loadingBar-progress-bar">
            <div 
                className="loadingBar-progress"
                style={{animation: `fill ${duration}s infinite`}}/>
        </div>
    </div>
  );
}

LoadingBar.propTypes = {
    duration: PropTypes.string,
}

export default LoadingBar;