import React from 'react';
import PropTypes from 'prop-types';
import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


/* eslint-disable react/jsx-props-no-spreading */
function animateLayoutChanges(args) {
    const {isSorting, wasDragging} = args;
  
    if (isSorting || wasDragging) {
      return defaultAnimateLayoutChanges(args);
    }
  
    return true;
  }

function SortableItem({ id, children, removeMode }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging 
    } = useSortable({ id: id.toString(),
        animateLayoutChanges,
     }); 

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    console.log(`isDragging for ${id}:`, isDragging);


    return (
        <div ref={setNodeRef} {...attributes} style={style} key={id.toString()}>
            {children({ listeners, removeMode })}
        </div>
    );
}

SortableItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.func.isRequired,  // Expecting children to be a function
    removeMode: PropTypes.bool.isRequired,
};

export default SortableItem;
